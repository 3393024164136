import React from 'react';
import {
    FaFile, FaFileAlt,
    FaFileArchive,
    FaFileImage,
    FaFilePdf,
    FaFileVideo,
    FaFileWord,
    FaFolder,
    FaImage
} from "react-icons/fa";
import useLongPress from "./useLongPress";
import Draggable from "react-draggable";

const Folder = ({name, onClick, isFile, onDragStart, onDrop})=>{
    const FileIcon=fileName=>{
        let ext=getFileExtension(fileName);
        ext=ext.toLowerCase();
        if(ext==='zip')
            return <FaFileArchive className="text-yellow-500 w-16 h-16"/>;
        else if(ext === 'jpg' || ext === 'png' || ext === 'webp' || ext === 'gif' || ext === 'jpeg')
            return <FaFileImage className="text-yellow-500 w-16 h-16"/>
        else if(ext === 'txt' || ext === 'doc' || ext === 'docx' || ext === 'xlsx' || ext === 'csv')
            return <FaFileWord className="text-yellow-500 w-16 h-16"/>
        else if (ext === 'pdf')
            return <FaFilePdf className="text-yellow-500 w-16 h-16"/>
        else if (ext === 'mp4' || ext === 'avi' || ext === 'mkv')
            return <FaFileVideo className="text-yellow-500 w-16 h-16"/>
        else return <FaFileAlt className="text-yellow-500 w-16 h-16"/>
    }
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const onLongPress = () => {
        console.log('longpress is triggered');
    };

    const longPress = useLongPress(onLongPress, onClick, defaultOptions);
    function getFileExtension(fileName){
        return fileName.split('.').pop();
    }

    function dragCapture(e) {
        console.log('dragged content',e);
    }


    return(
        <div draggable onDrag={onDragStart} onDrop={onDrop} onDragOver={(e)=>{e.stopPropagation();e.preventDefault();}} {...longPress} className="flex flex-col items-center bg-white shadow-lg rounded-lg p-4">
            {
               isFile ?  FileIcon(name):<FaFolder className="text-yellow-500 w-16 h-16"/>
            }
            <p onMouseDown={(e)=>{return false;}} unselectable="on" onSelect={(e)=>{return false;}} className="text-gray-700 mt-2">{name}</p>
        </div>
    );
}
export default Folder;