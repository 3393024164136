import React from 'react';
import { Card, CardContent, Grid, Typography, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LockIcon from '@mui/icons-material/Lock';
import ImageIcon from '@mui/icons-material/Image';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DescriptionIcon from '@mui/icons-material/Description';
import ArchiveIcon from '@mui/icons-material/Archive';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FileGrid = ({ files, onClick }) => {
    const getFileIcon = (mimeType) => {
        switch (mimeType) {
            case 'folder':
                return <FolderIcon />;
            case 'video':
                return <VideoLibraryIcon />;
            case 'audio':
                return <AudiotrackIcon />;
            case 'image':
                return <ImageIcon />;
            case 'zip':
                return <ArchiveIcon />;
            case 'document':
                return <DescriptionIcon />;
            default:
                return <InsertDriveFileIcon />;
        }
    };

    return (
        <Grid container spacing={3}>
            {files.map((file) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
                    <Card onClick={()=>onClick(file.id)}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton>
                                    {getFileIcon(file.mimeType)}
                                </IconButton>
                                <Typography variant="body1" style={{ flexGrow: 1 }}>
                                    {file.name}
                                </Typography>
                                {file.isEncrypted && (
                                    <LockIcon color="error" />
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default FileGrid;
