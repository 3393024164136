import React, {useState,useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {Form, Button} from "react-bootstrap";
import * as Constants from "../Constants";
import {toast} from "react-toastify";

function Dropzone() {
    const [files, setFiles] = useState([]);
    const [encrypt, setEncrypt] = useState(true);
    const [token, setToken] = useState("");
    const SERVER = Constants.SERVER;
    const [results,setResults]=useState([]);
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);
    const {getRootProps, getInputProps} = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });
    useEffect(()=>{
        if(localStorage.getItem("api_token")){
            let t=localStorage.getItem("api_token");
            console.log('TOKEN RET',t);
            setToken(t);
            checkToken(t);
        }else{
            window.location.replace("/login");
        }
    },[]);

    function checkToken(token) {
        const endpoint=Constants.SERVER+"/v1/encrypt/generic";
        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "text": "hello",
            }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("encrypt-RESPONSE", data);
                if(data.status==="success"){
                    //token valid
                }else{
                    notify("Your API key has been expired, please login with a valid API key!");
                    setTimeout(()=>{
                        localStorage.removeItem("api_token");
                        window.location.href='/login';
                    },2000);
                }
            })
            .catch(error => {
                console.log("ENCRYPT error", error);
            });
    }

    function resetForm(event) {
        setFiles([]);
        setResults([]);
    }

    function submit(event) {
        event.preventDefault();
        // upload the files to server
        let formData = new FormData();
        for(let i=0;i<files.length;i++){
            formData.append("files", files[i], files[i].path);
        }

        if(encrypt) {
            fetch(SERVER + '/v1/encrypt/binary', {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log("Response: ", response);
                    if (response.status === 'success') {
                        let links = response.links;
                        setResults(links);
                    }
                })
                .catch((err) => console.log(err));
        }else{
            fetch(SERVER + '/v1/decrypt/binary', {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log("Response: ", response);
                    if (response.status === 'success') {
                        let links = response.links;
                        setResults(links);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function optionChanged(event) {
        if (event.target.checked) {
            setEncrypt(true);
        }
    }

    function optionChanged2(event) {
        if (event.target.checked) {
            setEncrypt(false);
        }
    }

    function download(link,fname) {
        fetch(link,{
            method: 'GET',
            headers: {
                "Authorization": "Bearer "+token
            }
        })
            .then(response => response.blob())
            .then(result => {
                //let data= new Blob([result], {type: 'text/plain'});
                let csvURL=window.URL.createObjectURL(result);
                let tempLink=document.createElement('a');
                tempLink.href=csvURL;
                tempLink.setAttribute('download',fname);
                tempLink.click();

            })
            .catch(err=>console.log(err));

    }

    return (
        <div className="card-body">
            <h4 className="card-title">Encrypt Files</h4>
            <p className="card-description"> You can encrypt multiple files at once</p>
        <form className="forms-sample" onSubmit={submit}>

            <div className='form-group'>
                <div {...getRootProps()} className="w-full backdrop-blur bg-gray-700 p-8">
                    <input className="form-control form-control-lg" {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                    <div>
                        {files.map((file) => (
                            <img src={file.preview} alt={file.name} key={file.name}/>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex items-center mb-4">
                <input onClick={optionChanged} value="encrypt" id="radio-1" type="radio" name="radio" checked="checked"
                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="radio-1"
                       className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Encrypt</label>
            </div>
            <div className="flex items-center mb-4">
                <input onClick={optionChanged2} value="decrypt" id="radio-2" type="radio" name="radio"
                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="radio-2"
                       className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Decrypt</label>
            </div>
            <button type="submit" className="btn btn-primary me-2">{encrypt ? "Encrypt" : "Decrypt"}</button>
            <button type="reset" onClick={resetForm} className="btn btn-dark">Clear</button>
        </form>
            <div className='p-6'>
                <h3>{encrypt ? "Encryption ":"Decryption "}Results</h3>
                <table className="table">
                    <thead>
                     <tr>
                         <th>Original File Name</th>
                         <th>Download</th>
                     </tr>
                    </thead>
                    <tbody>
                    {
                        results.map((result,index)=>{
                           return (
                               <tr key={index}>
                                   <td>{result.original_file_name}</td>
                                   <td><button className='btn btn-primary' onClick={()=> download(result.link, result.original_file_name)}>Download</button></td>
                               </tr>
                           )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Dropzone;