import React, {useState, useEffect} from "react";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import NavBar from "../partials/NavBar";
import Terminal from "../partials/Terminal";
import ExcelReader from "../partials/ExcelReader";
import * as XLSX from "xlsx";
import * as Constants from "../Constants";

const Datashield=()=>{

    const [token, setToken] = useState("");
    const [file, setFile] = useState(null);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState(null);
    const [excelReader, setExcelReader] = useState(null);
    const SERVER=Constants.SERVER;

    useEffect(()=>{
        if(localStorage.getItem("api_token")){
            let t=localStorage.getItem("api_token");
            console.log('TOKEN RET',t);
            setToken(t);
        }else{
            window.location.replace("/login");
        }
    },[]);

    function getColumnData(data) {
        const keys = data[0];
        return keys.map((key) => {
            return {
                Header: key,
                accessor: (row) => row[keys.indexOf(key)], // Access the column of each row by index
            };
        });
    }
    function readExcel(value){
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const d = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            console.log(d);
            //const columnsCopy = getColumnData(d);
            //setColumns(columnsCopy);
            //parse string to data[][] array
            const lines=d.split("\n");
            const d2=[];
            for(let i=0;i<lines.length;i++){
                d2.push(lines[i].split(","));
            }
            setData(d2);
            //createReactTable();
        };
        reader.readAsBinaryString(value);
    }

    function inputChanged(event) {
        //setForm({ ...form, [event.target.name]: event.target.value });
        let value=event.target.files[0];
        console.log("input changed",value);
        setFile(value);
        readExcel(value);
    }
    function b64toBlob (b64Data, contentType='', sliceSize=512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    function encryptExcel(event) {
        event.preventDefault();
        console.log("encrypting excel");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("ignore", "1");
        fetch(SERVER + "/v1/encryptExcel", {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if(data.status==="success"){
                    let base64=data.base64;
                    let blob = b64toBlob(base64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                    readExcel(blob);
                }else{
                    alert("Error: "+data.message);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    return(
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">DataShield Demo</h4>
                                            <p className="card-description"> DataShield demo, this is an embedded version of datashield with only Excel & CSV support.</p>
                                            <form className="forms-sample p-2" onSubmit={encryptExcel}>

                                                <div className="form-group">
                                                    <label htmlFor="txtInput2">Select sqlite database:</label>
                                                    <input type="file" onInput={inputChanged} className="form-control h-100 text-white" id="txtInput2"
                                                           required={true} placeholder="Select a sqlite file"/>
                                                </div>
                                                <button type="submit" className="btn btn-primary me-2">Encrypt</button>
                                            </form>
                                            <ExcelReader style={{color: "white !important"}} className="text-white" data={data} columns={columns} excelReader={excelReader}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                    href="https://rapidapi.com/lokdon-lokdon-default/api/lokdon-ecsmid-v-1-0-0/" target="_blank">Get started with ECSMID</a></span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Datashield;