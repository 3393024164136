import React, { useState } from "react";
import {useParams} from "react-router-dom";

function Terminal(line) {
    const [output, setOutput] = useState("ECSMID V2");

    const handleInput = (e) => {
        //setInput(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO: implement logic for processing input and generating output
        setOutput(line.toUpperCase());
    };

    return (
        <div className="bg-black text-white p-6 my-5 rounded-lg w-full h-80 overflow-y-auto ">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-1">
                    <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                    <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                </div>
                <p className="text-sm">Terminal</p>
            </div>
            <div className="text-sm">
                {output && <p>{output}</p>}
                <form onSubmit={handleSubmit}>
                    <label htmlFor="input" className="sr-only">
                        Input
                    </label>
                    <textarea
                        id="input"
                        type="text"
                        rows={10}
                        value={line.line.line}
                        onChange={handleInput}
                        className={"bg-black outline-none w-full "+line.line.color}
                    />
                </form>
            </div>
        </div>
    );
}

export default Terminal;