import React from "react";
import PDFViewer from "../partials/PDFViewer";
import ReactPDF from "@react-pdf/renderer";

const Documentation=()=>{
    return (
        <div>
           <h3><a target='_blank' href='https://drive.google.com/file/d/1dTpezckicog8RXoM1uLUOZUv51_0FUqk/view?usp=drive_link'>ECSMID Documentation</a></h3>
            <h3><a target='_blank' href='https://www.npmjs.com/package/datashield'>DatashieldAi Documentation</a></h3>
        </div>
    );
}
export default Documentation;