import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import DatePicker from "react-datepicker"; // Assuming you're using this date picker
import "react-datepicker/dist/react-datepicker.css";
import {FaSpinner} from "react-icons/fa"; // Import date picker CSS
import loadingAnimation from '../anim/anim1.json';
import Lottie from 'react-lottie-player';
import {SERVER} from "../Constants";
import {toast, ToastContainer} from "react-toastify";
import moment from "moment";
// ... other imports for country select and switch

const CreateTokenForm = () => {
    // State for form inputs and error messages
    const [name, setName] = useState('');
    const [scope, setScope] = useState('');
    const [expiryDate, setExpiryDate] = useState(new Date(1714418550 * 1000));
    const [email, setEmail]=useState("");
    const [accessLevel, setAccessLevel] = useState('web'); // Default to 'web'
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);
    const [showModal, setShowModal]=useState(false);
    const [showToken, setShowToken]=useState("");
    // ... (Add more state variables for other fields and errors as needed)
    const [token, setToken] = useState("");
    useEffect(()=>{
        let t=localStorage.getItem("api_token");
        setToken(t);
    });
    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        if(expiryDate.getTime()<=new Date().getTime()){
            notify("Error: expiry date have to be future date");
            return;
        }
        if(email.length===0 || name.length===0 || scope==='' || expiryDate.getTime()<new Date().getTime()){
            notify("Error: please fill out all required fields");
            return;
        }
        toggleLoading();
        const body=JSON.stringify({
            name: name,
            expiry: Math.floor(expiryDate.getTime()/1000),
            scope: scope,
            email: email,
        });
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: body,
            redirect: "follow"
        };


        fetch(`${SERVER}/v1/auth/token/create`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                toggleLoading();
                if(result.status==='success'){
                    notify("Token saved");
                    setShowToken(result.data.token);
                    toggleModal();
                }else{
                    notify("Something went wrong");
                }
            })
            .catch((error) => console.log("error",error));
    };
    const [isLoading, setIsLoading] = useState(false);

    const toggleLoading = () => {
        setIsLoading(!isLoading);
    };
    const toggleModal = ()=>{
        setShowModal(!showModal);
    }
    const copyToClipboard=(e)=>{
        navigator.clipboard.writeText(showToken).then(() => {
            alert('Token copied to clipboard!');
        });
    }

    return (
        <div>
        <Form onSubmit={handleSubmit}>
            {/* Error Alert can be displayed here if needed */}

            <Form.Group as={Row} className="mb-3 text-white">
                <Form.Label column sm={2}>
                    Token Name
                </Form.Label>
                <Col sm={10}>
                    <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Descriptive Token Name, e.g Access Token for ECSMID"
                        className="text-white"
                        isInvalid={name.length === 0 || !/^[a-zA-Z\d\s]+$/.test(name)}
                    />
                    <Form.Control.Feedback type="invalid">
                        <p className="text-danger"><span className="mdi mdi-close-circle"/>Invalid token name, please
                            use descriptive name and only lower & upper-case latin characters </p>
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 text-white">
                <Form.Label column sm={2}>
                    Email
                </Form.Label>
                <Col sm={10}>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="User/Client Email for web access"
                        className="text-white"
                        isInvalid={name.length === 0}
                    />
                    <Form.Control.Feedback type="invalid">
                        <p className="text-danger"><span className="mdi mdi-close-circle"/>Invalid email address</p>
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                    Scope
                </Form.Label>
                <Col sm={10}>
                    <Form.Select
                        value={scope}
                        onChange={(e) => setScope(e.target.value)}
                        isInvalid={scope.length === 0}
                    >
                        <option value="">Select Scope</option>
                        <option value="1">Administrative Access</option>
                        <option value="0">API Client</option>
                        <option value="2">Test Only</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        <p className="text-danger"><span className="mdi mdi-close-circle"/>Please select scope</p>
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                    Expiry Date
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                    <p className="text-danger"><span className="mdi mdi-close-circle"/>Expiry date should be future
                        date</p>
                </Form.Control.Feedback>
                <Col sm={10}>
                    <Row>
                        <Col>
                            <DatePicker
                                selected={expiryDate}
                                onChange={(date) => setExpiryDate(date)}
                                className="form-control text-white text-white-500"
                                isInvalid={expiryDate === null || expiryDate.getTime() <= new Date().getTime()}
                                disabled={expiryDate.getTime() >= 4870092150 * 1000}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Never (Not Recommended)"
                                id="neverExpires"
                                checked={expiryDate.getTime() >= 4870092150 * 1000}
                                onChange={(e) => e.target.checked ? setExpiryDate(new Date(4870092150 * 1000)) : setExpiryDate(new Date())}
                                isInvalid={expiryDate === null || expiryDate.getTime() <= new Date().getTime()}
                                // ... Add onChange handler and logic to disable date picker
                            />
                        </Col>
                    </Row>

                </Col>
            </Form.Group>

            {/* ... Similar structure for Access Level, IP, Geolocation, and Custom Headers */}

            <Form.Group as={Row} className="mt-4">
                <Col sm={{span: 10, offset: 2}}>
                    <button type="submit" className="btn btn-primary btn-lg">{isLoading ? <Lottie animationData={loadingAnimation} play loop style={{width: 100, height: 30,}} />:<span className="text-lg">Create Token</span> }
                    </button>
                    {' '}
                </Col>
            </Form.Group>
            <ToastContainer />


        </Form>
    { showModal &&
    (<div className="modal fade show" style={{display: 'block'}} aria-modal="true" aria-labelledby="tokenModalLabel"
          aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content bg-gray-800 text-white">
                <div className="modal-header border-b border-gray-700">
                    <h5 className="modal-title text-2xl" id="tokenModalLabel">Your token has been created</h5>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>Your token for {email} has been created and will expire on <span
                        className="font-bold">{(expiryDate.getTime() >= 4870092150 * 1000) ? "NEVER":moment(expiryDate).format('MM/DD/YYYY')}</span>.</p>
                    <div className="bg-gray-700 p-2 rounded my-2">
                        <code id="tokenCode">{showToken}</code>
                    </div>
                    <button className="btn btn-primary" onClick={copyToClipboard}>Copy to Clipboard
                    </button>
                </div>
            </div>
        </div>
    </div>)
    }
    {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
    );
};

export default CreateTokenForm;