import React, {useEffect, useState} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import {ButtonGroup} from "@mui/material";
import {SERVER} from "../Constants";
import {toast, ToastContainer} from "react-toastify";

const SettingsForm = () => {
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);
    const [settings,setSettings]=useState({
        allowCustomHeaders: false,
        disableCRX: false,
        limitCPU: false,
        rateLimit: 0,
        enableThirdPartyAccess: false
    });
    const [token, setToken] = useState("");
    useEffect(()=>{
        let t=localStorage.getItem("api_token");
        setToken(t);
    });
    useEffect(()=>{
    },[settings])
    useEffect(()=>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${SERVER}/v1/auth/settings`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if(result.status==='success' && result.data.length>0){
                    setSettings(result.data[0]);
                }
            })
            .catch((error) => console.error(error));
    },[token]);
    const saveSettings=(e)=>{
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify(settings);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${SERVER}/v1/auth/settings/`+(settings.hasOwnProperty('id') ? 'update':'create'), requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if(result.status==='success'){
                    notify("Settings saved");
                }else {
                    notify("Something went wrong, changes not applied");
                }
            })
            .catch((error) => console.error(error));
    };
    return (
        <Form className="settings-form">

            <Form.Group as={Row} className="mb-4 align-items-center">
                <Form.Label column sm={4}>Allow Custom Headers:</Form.Label>
                <Col sm={8}>
                    <ButtonGroup>
                        <Button
                            variant={settings.allowCustomHeaders ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,allowCustomHeaders: true})}
                        >
                            On
                        </Button>
                        <Button
                            variant={!settings.allowCustomHeaders ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,allowCustomHeaders: false})}
                        >
                            Off
                        </Button>
                    </ButtonGroup>
                    {settings.allowCustomHeaders && (
                        <Form.Text className="text-warning">
                            Enabling this may expose sensitive information. Proceed with caution!
                        </Form.Text>
                    )}
                </Col>
            </Form.Group>


            {/* Disable Cross-Origin Check */}
            <Form.Group as={Row} className="mb-4 align-items-center">
                <Form.Label column sm={4}>Disable Cross-Origin Check:</Form.Label>
                <Col sm={8}>
                    <ButtonGroup>
                        <Button
                            variant={settings.disableCRX ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,disableCRX: true})}
                        >
                            On
                        </Button>
                        <Button
                            variant={!settings.disableCRX ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,disableCRX: false})}
                        >
                            Off
                        </Button>
                    </ButtonGroup>
                    {settings.disableCRX && (
                        <Form.Text className="text-warning">
                            Enabling this can put your users into security vulnerabilities. Make sure you understand the
                            implications
                            before proceeding.
                        </Form.Text>
                    )}
                </Col>
            </Form.Group>


            {/* Limit Service Usage (CPU Threshold) */}
            <Form.Group as={Row} className="mb-4 align-items-center">
                <Form.Label column sm={4}>Limit Service Usage if CPU Usage is Above 50%:</Form.Label>
                <Col sm={8}>
                    <ButtonGroup>
                        <Button
                            variant={settings.limitCPU ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,limitCPU: true})}
                        >
                            On
                        </Button>
                        <Button
                            variant={!settings.limitCPU ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,limitCPU: false})}
                        >
                            Off
                        </Button>
                    </ButtonGroup>
                    {!settings.limitCPU && (
                        <Form.Text className="text-warning">
                            Disabling this may impact service performance and stability.
                        </Form.Text>
                    )}
                </Col>
            </Form.Group>


            {/* Rate Limit for API Calls */}
            <Form.Group as={Row} className="mb-4 align-items-center text-white">
                <Form.Label column sm={4}>
                    Rate Limit for API Calls:
                </Form.Label>
                <Col sm={8}>
                    <select value={settings.rateLimit} onChange={(e)=>setSettings({...settings,rateLimit: Number(e.target.value)})} className="form-control-sm text-dark">
                        <option value="0">Unlimited</option>
                        <option value="100">100 calls/sec</option>
                        <option value="1000">1000 calls/sec</option>
                        <option value="10000">10000 calls/sec</option>
                        <option value="100000">100000 calls/sec</option>
                    </select>
                </Col>
            </Form.Group>


            {/* Allow Connected Apps to Create New Tokens */}
            <Form.Group as={Row} className="mb-4 align-items-center">
                <Form.Label column sm={4}>Allow Connected Apps to Create New Tokens:</Form.Label>
                <Col sm={8}>
                    <ButtonGroup>
                        <Button
                            variant={settings.enableThirdPartyAccess ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,enableThirdPartyAccess: true})}
                        >
                            On
                        </Button>
                        <Button
                            variant={!settings.enableThirdPartyAccess ? 'primary' : 'secondary'}
                            onClick={() => setSettings({...settings,enableThirdPartyAccess: false})}
                        >
                            Off
                        </Button>
                    </ButtonGroup>
                </Col>
            </Form.Group>
            <div className="row mb-4 align-items-center">
                <div className="col col-sm-4"></div>
                <button onClick={saveSettings} className="btn btn-lg btn-primary col-sm-8 max-w-sm mt-10">Save Changes</button>
            </div>
            <ToastContainer />
        </Form>
);
};

export default SettingsForm;