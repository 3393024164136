import React, {useState, useEffect} from "react";

const SideBar = () => {
    const [avatarUrl, setAvatarUrl] = useState('');
    const [name, setName] = useState('');

    async function getAvatar(hash) {
        const url = `https://www.gravatar.com/avatar/${hash}?d=404`;
        const response = await fetch(url);
        if (response.status === 404) {
            return null;
        }
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setAvatarUrl(objectUrl);
    }

    useEffect(() => {
        let hash = localStorage.getItem("user_hash");
        let name = localStorage.getItem("name");
        if (name) {
            setName(name);
        }
        if (hash) {
            getAvatar(hash);
        }
    }, []);
    return (
        <nav className="sidebar sidebar-offcanvas text-black" id="sidebar">
            <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                <a className="sidebar-brand brand-logo" href="index.html"><img className="w-50"
                                                                               src="/assets/images/lokdon_logo.png"
                                                                               alt="logo"/></a>
                <a className="sidebar-brand brand-logo-mini" href="index.html"><img src="/assets/images/lokdon_logo.png"
                                                                                    alt="logo"/></a>
            </div>
            <ul className="nav text-black">
                <li className="nav-item profile text-black">
                    <div className="profile-desc">
                        <div className="profile-pic">
                            <div className="count-indicator">
                                <img className="img-xs rounded-circle " src={avatarUrl} alt=""/>
                                <span className="count bg-success"></span>
                            </div>
                            <div className="profile-name">
                                <h5 className="mb-0 font-weight-normal text-white">{name}</h5>
                                <span>Admin</span>
                            </div>
                        </div>
                        <a href="#" id="profile-dropdown" data-bs-toggle="dropdown"><i
                            className="mdi mdi-dots-vertical"></i></a>

                    </div>
                </li>
                <li className="nav-item nav-category">
                    <span className="nav-link">Navigation</span>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/">
        <span className="menu-icon">
          <i className="mdi mdi-speedometer"></i>
        </span>
                        <span className="menu-title">Dashboard</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/access-tokens">
        <span className="menu-icon">
          <i className="mdi mdi-security"></i>
        </span>
                        <span className="menu-title">Access Tokens</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/encrypt-text">
        <span className="menu-icon">
          <i className="mdi mdi-playlist-play"></i>
        </span>
                        <span className="menu-title">Encrypt Text</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/encrypt-file">
        <span className="menu-icon">
          <i className="mdi mdi-table-large"></i>
        </span>
                        <span className="menu-title">Encrypt Files</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/ecsmid-cloud">
        <span className="menu-icon">
          <i className="mdi mdi-cloud"></i>
        </span>
                        <span className="menu-title">ECSMID Cloud</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/ecsmid-ftp">
        <span className="menu-icon">
          <i className="mdi mdi-chart-bar"></i>
        </span>
                        <span className="menu-title">Secure File Transfer</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="/datashield">
        <span className="menu-icon">
          <i className="mdi mdi-security-network"></i>
        </span>
                        <span className="menu-title">Try Data-shield AI</span>
                    </a>
                </li>
                <li className="nav-item menu-items">
                    <a className="nav-link" href="documentation">
        <span className="menu-icon">
          <i className="mdi mdi-file-document-box"></i>
        </span>
                        <span className="menu-title">Documentation</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
}
export default SideBar;