import React, {useEffect, useState} from "react";

const NavBar = () => {
    const[name,setName]=useState('');
    const[avatarUrl,setAvatarUrl]=useState('');
    async function getAvatar(hash) {
        const url = `https://www.gravatar.com/avatar/${hash}?d=404`;
        const response = await fetch(url);
        if (response.status === 404) {
            return null;
        }
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setAvatarUrl(objectUrl);
    }

    useEffect(()=>{
        let hash=localStorage.getItem("user_hash");
        let name=localStorage.getItem("name");
        if(name){
            setName(name);
        }
        if(hash){
            getAvatar(hash);
        }
    },[]);

    function handleLogout(e) {
        e.preventDefault();
        localStorage.clear();
        window.location.replace("/login");
    }

    return (
        <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                <a className="navbar-brand brand-logo-mini" href="index.html"><img src="assets/images/lokdon_mini.png"
                                                                                   alt="logo"/></a>
            </div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                <button className="navbar-toggler navbar-toggler align-self-center" type="button"
                        data-toggle="minimize">
                    <span className="mdi mdi-menu"></span>
                </button>
                <ul className="navbar-nav w-100">
                    <li className="nav-item w-100">
                        <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                            <input type="text" className="form-control" placeholder="Search"/>
                        </form>
                    </li>
                </ul>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item dropdown d-none d-lg-block">
                        <a className="nav-link btn btn-success create-new-button" id="createbuttonDropdown"
                           data-bs-toggle="dropdown" aria-expanded="false" href="#">API PlayGround</a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                             aria-labelledby="createbuttonDropdown">
                            <h6 className="p-3 mb-0">API Features</h6>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item" href="/encrypt-text">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-lock text-primary"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject ellipsis mb-1">Try Cryptography APIs</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item" href="/ecsmid-ftp">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-server-network text-info"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject ellipsis mb-1">Secure File Transfer</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item" href="/datashield">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-speedometer text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject ellipsis mb-1">Try Datashield AI (in browser)</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <p className="p-3 mb-0 text-center">See more features</p>
                        </div>
                    </li>
                    <li className="nav-item nav-settings d-none d-lg-block">
                        <a className="nav-link" href="#">
                            <i className="mdi mdi-view-grid"></i>
                        </a>
                    </li>
                    <li className="nav-item dropdown border-left">
                        <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#"
                           data-bs-toggle="dropdown">
                            <i className="mdi mdi-bell"></i>
                            <span className="count bg-danger"></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                             aria-labelledby="notificationDropdown">
                            <h6 className="p-3 mb-0">Notifications</h6>
                            <div className="dropdown-divider"></div>
                            {/*<a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-calendar text-success"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Important: quota limit</p>
                                    <p className="text-muted ellipsis mb-0"> You are reaching your quota limits </p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-settings text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Update Available</p>
                                    <p className="text-muted ellipsis mb-0"> ECSMID update is available, click here to learn more </p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-link-variant text-warning"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Launch Admin</p>
                                    <p className="text-muted ellipsis mb-0"> New admin wow! </p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>*/}
                            <p className="p-3 mb-0 text-center">No new notifications</p>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" id="profileDropdown" href="#" data-bs-toggle="dropdown">
                            <div className="navbar-profile">
                                <img className="img-xs rounded-circle" src={avatarUrl} alt=""/>
                                    <p className="mb-0 d-none d-sm-block navbar-profile-name">{name} (Admin)</p>
                                    <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                             aria-labelledby="profileDropdown">
                            <h6 className="p-3 mb-0">Profile</h6>
                            <div className="dropdown-divider"></div>
                            {/*<a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-settings text-success"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Settings</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>*/}
                            <a className="dropdown-item preview-item" onClick={handleLogout}>
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-logout text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Log out</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        data-toggle="offcanvas">
                    <span className="mdi mdi-format-line-spacing"></span>
                </button>
            </div>
        </nav>
        );
}
export default NavBar;