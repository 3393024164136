import React, {useState, useEffect} from "react";
import TopBanner from "src/partials/TopBanner";
import SideBar from "src/partials/SideBar";
import NavBar from "src/partials/NavBar";
import Terminal from "../partials/Terminal";
import ExcelReader from "../partials/ExcelReader";
import * as XLSX from "xlsx";
import * as Constants from "src/Constants";
import {useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import FileSelectorModal from "../partials/FileSelectorModal";
import GoogleSignIn from "../partials/GoogleSignIn";

const DatashieldAI=()=>{

    const [token, setToken] = useState("");
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [mainInit, setMainInit] = useState(false);
    const [isEncrypt, setIsEncrypt] = useState(true);
    const [sheetID, setSheetID]= useState("");
    const SERVER=Constants.SERVER;
    const [redirect,setRedirect]=useState("");
    const [authorized,setAuthorized]=useState(false);
    const [sheetURL,setSheetURL]=useState("");
    const [avoidFirstRow,setAvoidFirstRow]=useState(true);
    const [avoidFirstColumn, setAvoidFirstColumn]=useState(false);
    const [classNames, setClassNames]=useState([]);
    const [columnStatus, setColumnStatus]=useState([]);
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);
    const params= useParams();
    const classEncrypted="mdi mdi-check-circle text-xl text-green text-green-500";
    const classPlain="mdi mdi-alert-circle text-xl text-red text-red-500"
    const [loadingData,setLoadingData]=useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRows,setSelectedRows]=useState([]);
    const [sheets, setSheets]=useState([]);
    const [activePage, setActivePage]=useState(0);
    const [code, setCode]=useState('');
    const [showFileSelectorModal, setShowFileSelectorModal]=useState(false);
    const [currentFileName, setCurrentFileName]=useState('');
    const [driveFiles, setDriveFiles]= useState([{
        id: 5,
        name: "File 1",
    },
        {
            id: 1,
            name: "File 2"
        }]);

    useEffect(()=>{
        if(localStorage.getItem("api_token"))
            setToken(localStorage.getItem("api_token"));
       // window.main();
        setMainInit(!mainInit);
        //testRead();
    },[]);



    useEffect(()=>{
        console.log('#####>Params changed: ',params);

        if(localStorage.getItem("api_token"))
            setToken(localStorage.getItem("api_token"));
        else{
            window.location.replace('/login');
        }
        /*if(params.hasOwnProperty("sheetID")) {
            setSheetID(params.sheetID);
            setSheetURL("https://docs.google.com/spreadsheets/d/"+params.sheetID+"/");
            setAuthorized(true);
            checkSheetStatus(params.sheetID);
            readSheetData(params.sheetID);
        }*/
        if(params.hasOwnProperty("authorized")){
            console.log('setting authorized');
            setAuthorized(true);
            console.log('getting list of files');
            getListOfFiles();
        }

    },[params]);
    useEffect(()=>{
        if(activePage>0 && sheetID){
            readSheetData(sheetID);
        }
    },[activePage]);
    useEffect(()=>{sheetID && readSheetData(sheetID)},[sheetID]);

    const toggleFileSelectorModal=()=>{
        setShowFileSelectorModal(!showFileSelectorModal);
    }
    const toggleIsLoading=()=>{setIsLoading(!isLoading);}

    function detectColumnStatus(data,columns) {
        console.log("#####>Detecting col status",data,columns);
        const colStatus=[...columnStatus];
        const cNames=[...classNames];
        for(let i=0;i<columns.length;i++){
            let decryptCount=0;
            for(let j=0;j<data.length;j++){
                if(shouldDecrypt(data[j][i]))
                    decryptCount++;
            }
            if(decryptCount>data.length/2){
                colStatus[i]=true;
                cNames[i]=classEncrypted;
            }else{
                colStatus[i]=false;
                cNames[i]=classPlain;
            }
        }
        setColumnStatus([...colStatus]);
        setClassNames([...cNames]);
        console.log('after detect',colStatus,cNames);
    }
    function shouldDecrypt(value){
        if(value===undefined || value===null)
            return false;
        let uniCount=0;
        for(let i=0;i<value.length;i++){
            if(value.charCodeAt(i)>127){
                uniCount++;
            }
        }
        return uniCount>value.length/10;
    }

    function getListOfFiles(){
        const t=token || localStorage.getItem("api_token");
        fetch(`${SERVER}/api/v1/list-files?id=${t}`)
            .then((result)=>result.json())
            .then((result)=>{
                console.log('fetch files',result);
                if(result.status==='success'){
                    setDriveFiles(result.data);
                    toggleFileSelectorModal();
                }else{
                    notify('Authorization failed, please try again!');
                }
            })
            .catch((err)=>console.log(err));
    }


    function decrypt(val) {
        // domain expert (Ubong) should investigate and determine if this
        // should run asynchronously or synchronous
        // right now it's synchronous
        if (!mainInit) {
            window.main();
            console.log("main initialized");
        }
        return window.main.api.decrypt(val);
    }
    function encrypt(val) {
        // domain expert (Ubong) should investigate and determine if this
        // should run asynchronously or synchronous
        // right now it's synchronous
        if (!mainInit) {
            window.main();
            console.log("main initialized");
        }
        return window.main.api.encrypt(val);
    }

    function setColumnsFromJsonArray() {
        const keys = data[0];
        const tempColumns=[];
        Object.keys(keys).forEach((key)=>{
           tempColumns.push(key);
        });
        console.log('column data',tempColumns);
        setColumns([...tempColumns]);
    }


    function readJSON(value) {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            console.log('original content',bstr);
            const d = JSON.parse(bstr);
            console.log('parsed data: ',d);
            //const columnsCopy = getColumnData(d);
            //setColumns(columnsCopy);
            //parse string to data[][] array
            setData([...d]);
            //createReactTable();
        };
        reader.readAsBinaryString(value);
    }

    function readExcel(values){
        console.log("total rows read from excel",values.length);
        const d=[];
        const col=[];
        for(let i=0;i<values.length;i++){
            let row=values[i];
            if(i===0){
                console.log('setting rows: ',row);
                for(let j=0;j<row.length;j++){
                    col.push(row[j]);
                }
                setColumns([...col]);
            }else{
                d.push(row);
            }
        }
        setData([...d]);
        detectColumnStatus(d,col);
    }

    function inputChanged(event) {
        let value=event.target.files[0];
        console.log("input changed",value);
        setIsEncrypt(true);
        setFile(value);
        readJSON(value);
    }
    function readSheetData(sheetID) {
        //setLoadingData("visually-hidden");
        toggleIsLoading();
        let endpoint=Constants.SERVER+"/api/v1/sheets/read/"+sheetID;
        if(activePage>0){
            endpoint=endpoint+"/"+activePage;
        }
        endpoint=endpoint+"?id="+(token.length>0 ? token:localStorage.getItem("api_token"));
        /*if(localStorage.getItem("credential")){
            let credential=localStorage.getItem("credential");
            if(credential!==undefined){
                endpoint+=credential;
            }else{
                endpoint+="na";
            }
        }else{
            endpoint+="na";
        }*/
        fetch(endpoint,{
            headers: {
                "Authorization":"Bearer "+(token.length>0 ? token:localStorage.getItem("api_token")),
            }
        })
            .then((result)=> {
                toggleIsLoading();
                return result.json();
            })
            .then((result)=>{
                console.log("response/read/excel",result);

                setLoadingData("table table-responsive");
                if(result.status==='success'){
                    let values=result.values;

                    readExcel(values);
                    const sheetIDs=result.sheets;
                    if(sheetIDs!==undefined && sheetIDs!==null && sheetIDs.length>0){
                       setSheets([...sheetIDs]);
                       setActivePage(activePage > 0 ? activePage:sheetIDs[0].id);
                    }
                }else{
                    notify(result.message);
                }
            }).catch((err)=> {
            console.log(err);
        });
    }

    function cryptColumn(index,action){
        const bookName=sheets.find(sheet=> Number(sheet.id)===Number(activePage)).name;
        if(!bookName){
            notify('Book Name missing');
            return;
        }
        let endpoint=Constants.SERVER+"/api/v1/sheets/"+action+"/"+sheetID+"/"+bookName+"/"+index;
        setLoadingData("visually-hidden");
        const t=(token.length>0 ? token:localStorage.getItem("api_token"));
        fetch(endpoint+"?id="+t,{
            headers: {
                "Authorization":"Bearer "+t,
            }
        }).then((response)=>response.json())
            .then((response)=>{
                setLoadingData("table table-responsive");
                console.log(action+' response',response);
                if(response.status==='success'){
                    notify(response.message);
                    readSheetData(sheetID);
                }else{
                    notify(response.message)
                }
            })
            .catch((err)=>console.log(err));
    }

    /*function onSubmit(e) {
        e.preventDefault();
        if(data.length===0){
            alert("Please select a file");
            return;
        }else{
            console.log('processing...');
            let tempData=[...data];
            tempData.map((row, index)=>{
               Object.keys(row).map((key, index)=>{
                     //console.log('encrypting: ',row[key]);
                   if(isEncrypt)
                     row[key]=encrypt(row[key]);
                   else row[key]=decrypt(row[key]);
               });
            });
            console.log('after encryption: ',tempData);
            setData([...tempData]);
            setIsEncrypt(false);
        }
    }*/
    function onSubmit(e){
        e.preventDefault();
        if(!authorized && redirect.length>0){
            window.location.replace(redirect);
        }else if(authorized){
            //encrypt or decrypt
            /*let action=isEncrypt ? "encrypt":"decrypt";
            let endpoint=Constants.SERVER+"/v1/sheets/"+action+"/"+sheetID;
            fetch(endpoint,{
                headers: {
                    "Authorization":"Bearer "+(token.length>0 ? token:localStorage.getItem("api_token")),
                }
            }).then((response)=>response.json())
                .then((response)=>{
                    console.log(action+' response',response);
                    if(response.status==='success'){
                        notify(response.message);
                        setTimeout(()=>{
                            window.location.href="/datashield";
                        },4000);
                    }else{
                        notify(response.message)
                    }
                })
                .catch((err)=>console.log(err));*/
        }
    }

    function checkSheetStatus(sheet_id){
        let endpoint=Constants.SERVER+"/v1/sheets/get/"+sheet_id;
        fetch(endpoint,{
            headers: {
                "Authorization":"Bearer "+(token.length>0 ? token:localStorage.getItem("api_token")),
            }
        }).then((response)=>response.json())
            .then((response)=>{
                console.log('response',response);
                if(response.status==='success'){
                    setIsEncrypt(!response.sheet.encrypted);
                    setRedirect(response.redirect);
                }
            })
            .catch((err)=>console.log(err));
    }

    function jumpFirstRow(checked) {
        setAvoidFirstRow(checked);
    }
    function jumpFirstCol(checked){
        setAvoidFirstColumn(checked);
    }

    function cryptUpdate(index) {
        console.log("updating col status at",index,columnStatus[index]);
        const newColStatus=[...columnStatus];
        const newClasses=[...classNames];
        newColStatus[index]=columnStatus[index] === false;
        newClasses[index]=newColStatus[index]===false ? classPlain:classEncrypted;
        setClassNames([...newClasses]);
        setColumnStatus([...newColStatus]);
        cryptColumn(index,newColStatus[index]===true ? "encrypt":"decrypt");
    }

    function updateSelection(index) {
        let copy=[...selectedRows];
        if(copy.length>index){
            copy[index]=copy[index]===false;
        }else{
            for(let i=0;i<data.length;i++){
                if(i===index)
                    copy[index]=true;
                else copy[i]=false;
            }
        }
        setSelectedRows([...copy]);
        console.log('selected rows after update',selectedRows);
    }

    function encryptSelected(e) {
        const bookName=sheets.find(sheet=> Number(sheet.id)===Number(activePage)).name;
        const t=(token.length>0 ? token:localStorage.getItem("api_token"));
        if(!bookName){
            notify('Book Name missing');
            return;
        }
        let indexList=[];
        for(let i=0;i<selectedRows.length;i++){
            if(selectedRows[i]===true){
                indexList.push(i);
            }
        }
        let body={
            "selectedRows": indexList
        };
        const endpoint=Constants.SERVER+"/api/v1/sheets/encrypt/row/"+sheetID+"/"+bookName;

        fetch(endpoint+"?id="+t,{
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Authorization":"Bearer "+(token.length>0 ? token:localStorage.getItem("api_token")),
                "Content-Type":"application/json"
            }
        })
            .then((result)=>result.json())
            .then((result)=>{
                if(result.status==='success'){
                    notify(result.message);
                    readSheetData(sheetID);
                }else{
                    notify(result.message);
                }
            })
            .catch((err)=>console.log("err",err));

    }

    function decryptSelected(e) {
        const bookName=sheets.find(sheet=> Number(sheet.id)===Number(activePage)).name;
        const t=(token.length>0 ? token:localStorage.getItem("api_token"));
        if(!bookName){
            notify('Book Name missing');
            return;
        }
        let indexList=[];
        for(let i=0;i<selectedRows.length;i++){
            if(selectedRows[i]===true){
                indexList.push(i);
            }
        }
        let body={
            "selectedRows": indexList
        };
        const endpoint=Constants.SERVER+"/api/v1/sheets/decrypt/row/"+sheetID+"/"+bookName;

        fetch(endpoint+"?id="+t,{
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Authorization":"Bearer "+(token.length>0 ? token:localStorage.getItem("api_token")),
                "Content-Type":"application/json"
            }
        })
            .then((result)=>result.json())
            .then((result)=>{
                if(result.status==='success'){
                    notify(result.message);
                    readSheetData(sheetID);
                }else{
                    notify(result.message);
                }
            })
            .catch((err)=>console.log("err",err));
    }

    function resetSelection(e) {
        setSelectedRows([]);
    }

    function sheetChanged(e) {
        console.log("sheetChanged",e);
        setActivePage(e.target.value);
    }

    return(
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body" style={{overflowX: "auto"}}>
                                            <h4 className="card-title">DataShield Demo</h4>
                                            <p className="card-description"> DataShield demo, this is an embedded version of datashield with limited support. Get the full datashield-AI from https://lokdon.com.</p>
                                            <form className="forms-sample p-2" onSubmit={onSubmit}>

                                                 <div className="form-group">
                                                     {!authorized ? <GoogleSignIn oauthUrl={`${SERVER}/api/v1/auth?id=${token}`} />:<div>
                                                         <button className="btn btn-danger btn-lg" onClick={(e)=>toggleFileSelectorModal()}>Change Selected File</button>
                                                     </div>}
                                                    {/*<label htmlFor="txtInput2">Enter a link to your Google Sheets document:</label>*/}
                                                    {/*/!*<input type="file" onInput={inputChanged} className="form-control h-100 text-white" id="txtInput2"*/}
                                                    {/*       required={true} placeholder="Select a sqlite file"/>*!/*/}
                                                    {/*<input type="url" onInput={inputChangedUrl} value={sheetURL.length>0 ? sheetURL:""} className="form-control text-white" id="textInput2" required={true} placeholder="Enter Google Sheets URL"/>*/}
                                                </div>
                                                <div className="form-group form-check visually-hidden">
                                                    <label className="form-check-label w-50 px-60 text-white ">
                                                        <input type="checkbox" className="form-check-input" id="cboIsAuto" checked={avoidFirstRow} onChange={(event)=>{
                                                            jumpFirstRow(event.target.checked);
                                                        }}/>
                                                        Don't encrypt the first row
                                                        <i className="input-helper"></i></label>
                                                </div>
                                                <div className="form-group form-check visually-hidden">
                                                    <label className="form-check-label w-50 px-60 text-white ">
                                                        <input type="checkbox" className="form-check-input" id="cboIsAuto" checked={avoidFirstColumn} onChange={(event)=>{
                                                            jumpFirstCol(event.target.checked);
                                                        }}/>
                                                        Don't encrypt the first column
                                                        <i className="input-helper"></i></label>
                                                </div>
                                                {/*<button type="submit" className={"btn btn-primary me-2 "}>{!authorized ? "Authorize":<i className="mdi mdi-check-circle">Authorized</i>}</button>*/}

                                            </form>
                                            <div className={selectedRows.length > 0 ? "p-2":"visually-hidden"}>
                                            <button onClick={encryptSelected} className="btn btn-primary p-2 m-2">Encrypt Selected</button>
                                            <button onClick={decryptSelected} className="btn btn-secondary p-2 m-2">Decrypt Selected</button>
                                            <button onClick={resetSelection} className="btn btn-danger p-2 m-2">Clear Selection</button>
                                            </div>
                                            <div className={sheets.length > 0 ? "p-2 w-50 flex-row flex align-content-center justify-items-center align-items-center":"visually-hidden"}>
                                                <label className="p-1 text-lg">Book: </label>
                                                <select name="page" className="form-control form-control-sm w-25 text-white text-lg" onChange={sheetChanged} value={activePage}>
                                                    {
                                                        sheets.map((value, index)=>{
                                                            return (
                                                                <option key={index} value={value.id}>{value.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {currentFileName && <p className="text-2xl text-white">Current File: <strong>{currentFileName}</strong></p>}
                                            <table className={loadingData}>
                                               <thead>
                                                  <tr>
                                                      <th className="p-2">Select</th>
                                                      {
                                                            columns.map((column, index) => {
                                                                return <th className="text-primary p-2" onClick={(e)=>(cryptUpdate(index))} key={index}>{column} <i onClick={(e)=>(cryptUpdate(index))}  className={classNames[index]}></i></th>
                                                            })
                                                      }
                                                  </tr>
                                               </thead>
                                                <tbody>
                                                {
                                                    data.map((row, index) => {
                                                        return <tr className="p-2" key={index}>
                                                            <td className="p2"><input type="checkbox" className="form-check-input" id="cboIsAuto" checked={selectedRows.length>index && selectedRows[index]===true} onChange={(event)=>{updateSelection(index)}}/></td>
                                                            {
                                                                row.map((val,index)=>{
                                                                  return <td key={index}>{val}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            {
                                                isLoading && <h1 className="text-2xl">Loading please wait!</h1>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                    href="https://rapidapi.com/lokdon-lokdon-default/api/lokdon-ecsmid-v-1-0-0/" target="_blank">Get started with ECSMID</a></span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            {
                showFileSelectorModal &&
                <FileSelectorModal files={driveFiles} onSelectFile={(id,name) => {
                    setSheetID(id);
                    setActivePage(0);
                    setCurrentFileName(name);
                }} onClose={(e) => toggleFileSelectorModal()}/>
            }
        </div>
    );
}
export default DatashieldAI;