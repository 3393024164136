import React, {useEffect, useState} from "react";
import { Document, Page } from "react-pdf";

function PDFViewer({props}) {
    // The pdf file url
    const file = props.file;
    useEffect(()=>{
        console.log('PDF file: ',file,props);

    },[]);

    // The current page number
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    // Callback function when document is loaded
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    // Function to go to the previous page
    function goPrevPage() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    // Function to go to the next page
    function goNextPage() {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    return (
        <div className="pdf-viewer">
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
            <div className="page-controls">
                <button onClick={goPrevPage}>Prev</button>
                <span>
          Page {pageNumber} of {numPages}
        </span>
                <button onClick={goNextPage}>Next</button>
            </div>
        </div>
    );
}

export default PDFViewer;
