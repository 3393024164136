import React, {useEffect, useState} from "react";
import * as Constants from "../Constants";
import NavBar from "../partials/NavBar";
import md5 from 'crypto-js/md5';

const Login=()=>{
    const [token,setToken]=useState("");
    const [email, setEmail] = useState("");
    useEffect(()=>{
        if(localStorage.getItem("api_token")){
            setToken(localStorage.getItem("api_token"));
            window.location.replace("/");
        }
    },[]);

    function signIn(event){
        event.preventDefault();
        if(token===''){

        }else{
            let endpoint=Constants.SERVER+"/v1/auth/login";
            fetch(endpoint,{
                method: "POST",
                body: JSON.stringify({
                    text: email,
                }),
                headers: {
                    "Authorization":"Bearer "+token,
                    "Content-Type":"application/json"
                }
            })
                .then((result)=> result.json())
                .then((result)=>{
                    console.log(result);
                    const hash=md5(email).toString();
                    const name=email.split('@')[0];
                    if(result.status==="success"){
                        localStorage.setItem("api_token",token);
                        localStorage.setItem("user_hash",hash);
                        localStorage.setItem("name",name);
                        window.location.replace("/");
                    }else{
                        alert(result.message || "This license has been expired!");
                    }
                })
                .catch((err)=> {
                    console.log(err);
                    alert('Invalid token!');
                });
        }
    }

    function tokenUpdated(event) {
        setToken(event.target.value);
    }
    function emailUpdated(event){
        setEmail(event.target.value);
    }

    return(
        <div className="App">

            <div className="container">
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">

                                    <div className="card">
                                        <div className="card-body">
                                            <h1 className="card-title">Login To ECSMID</h1>
                                            <form onSubmit={signIn}>
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input onInput={emailUpdated} type="email" className="form-control text-white" id="email" placeholder="email"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="token">API KEY</label>
                                                    <input onInput={tokenUpdated} type="text" className="form-control form-control-lg text-white" id="email" placeholder="token" required={true}/>
                                                </div>
                                                <button className="btn btn-primary btn-lg">Login</button>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Login;