import React, {useEffect, useState} from 'react';
import {FaFolder} from "react-icons/fa";

const DirViewer=({path,render, isRemote})=>{
    const [files,setFiles]=useState([]);
    const [templateFolder]=useState(` <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-4">
                <FaFolder className="text-yellow-500 w-16 h-16"/>
                <p className="text-gray-700 mt-2">Root</p>
            </div>`);

    useEffect(()=>{
        //testInit();
        console.log('params',path,render);
        console.log('##########> PATH FROM PARAM',path);
        window.path=path;
    },[]);

    function testInit(){
        let f=[
                    {
                        path: '/home',
                        name: 'home',
                        children: []
                    },
                    {
                        path: '/etc',
                        name: 'etc',
                        children: []
                    },
                    {
                        path: '/sys',
                        name: 'sys',
                        children: []
                    },
                    {
                        path: '/var',
                        name: 'var',
                        children: []
                    },
                    {
                        path: '/var',
                        name: 'var',
                        children: [
                            {
                                path: '/var/www',
                                name: 'www',
                                children: []
                            },
                        ]
                    },
        ];
        setFiles(f);
        //setCurrentPath(f);

    }



    return(
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4'>
            {
                path.map(folder=> { return render({folder: folder, isRemote: isRemote})})
            }
        </div>
    )
};
export default DirViewer;