import React, { useState } from 'react';
import { Alert as MuiAlert, AlertTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Alert = ({ alert }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    if (!open) return null;

    return (
        <MuiAlert
            severity={alert.status || 'success'}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            sx={{ mb: 2 }}
        >
            <AlertTitle>{alert.status ? alert.status.charAt(0).toUpperCase() + alert.status.slice(1) : 'Success'}</AlertTitle>
            {alert.message}
        </MuiAlert>
    );
};

export default Alert;
