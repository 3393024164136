import React, {useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import {toast} from "react-toastify";
import {SERVER} from "../Constants"; // For date formatting

// Sample data (replace with your actual data)


const AccessTokensTable = () => {
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);
    const [tokens,setTokens] = useState([]);

    const [showToken, setShowToken]=useState("");
    // ... (Add more state variables for other fields and errors as needed)
    const [token, setToken] = useState("");
    useEffect(()=>{
        let t=localStorage.getItem("api_token");
        setToken(t);
    });
    useEffect(()=>{
        loadTokens();
    },[token]);

    const loadTokens=()=>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${SERVER}/v1/auth/tokens`, requestOptions)
            .then((response) => response.json())
            .then((result) =>{
                if(result.status==='success'){
                    setTokens(result.data);
                }
            })
            .catch((error) => console.error(error));
    }
    const updateToken=(t)=>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type","application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify(t)
        };

        fetch(`${SERVER}/v1/auth/token/update`, requestOptions)
            .then((response) => response.json())
            .then((result) =>{
                if(result.status==='success'){
                    notify("Token status updated");
                    window.setTimeout(()=>{window.location.reload()},2000);
                }
            })
            .catch((error) => console.error(error));
    }
    const toggleTokenStatus=(t)=>{
        const result=tokens.find(obj => {
            return obj.id===t.id
        });
        if(result){
            result.active=!result.active;
            t.active=result.active;
            setTokens(prevTokens=> prevTokens.map(tok=> tok.id===t.id ? t:tok));
            updateToken(result);
        }
    }

    const unwrap=(key)=>{

    }
    const copyToClipboard=(t)=>{
        navigator.clipboard.writeText(t).then(() => {
            alert('Token copied to clipboard!');
        });
    }
    return (
        <Table bordered hover className="text-white hover:text-white">
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Token</th>
                <th>Scope</th>
                <th>Expires</th>
                <th>Last Used</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {tokens.map((t) => (
                <tr key={t.name}>
                    <td>{t.name}</td>
                    <td>{t.email}</td>
                    <td>
                        *******************************{t.token && t.token.substring(t.token.length-6)}
                        <span className="mdi mdi-clipboard float-right text-lg" onClick={() => { copyToClipboard(t.token) }}>
              </span>
                    </td>
                    <td>{t.scope=="0" ? "API Access":(t.scope=="1" ? "Admin Access":"Test Token")}</td>
                    <td>{moment(t.expiry).format('MM/DD/YYYY')}</td>
                    <td>{t.lastUsed===0 ? "Never":moment(t.lastUsed).format('MM/DD/YYYY')}</td>
                    <td>
                        {t.active ? <button className='btn btn-warning' onClick={()=>toggleTokenStatus(t)}>Deactivate</button>:<button className='btn btn-success' onClick={()=>toggleTokenStatus(t)}>Activate</button>}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default AccessTokensTable;