import {Tab, Tabs} from "react-bootstrap";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import React from "react";
import NavBar from "../partials/NavBar";
import AccessTokensTable from "../partials/AccessTokensTable";
import CreateTokenForm from "../partials/CreateTokenForm";
import SettingsForm from "../partials/SettingsForm";

const AccessTokens = () => {
    return (
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <Tabs defaultActiveKey="tokens" id="access-tokens-tabs" className="mb-3">
                                    <Tab eventKey="tokens" title="Access Tokens">
                                        <span className="me-2 mdi mdi-key"/>
                                        <AccessTokensTable />
                                    </Tab>
                                    <Tab eventKey="create" title="Create New Access Token">
                                        <span className="me-2 mdi mdi-plus-circle-outline"/>
                                        <CreateTokenForm />
                                    </Tab>
                                    <Tab eventKey="settings" title="Settings">
                                        <span className="me-2 mdi mdi-settings-outline"/>
                                        <SettingsForm />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                    href="https://rapidapi.com/lokdon-lokdon-default/api/lokdon-ecsmid-v-1-0-0/"
                                    target="_blank">Get started with ECSMID</a></span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AccessTokens;