import React from 'react';

const GoogleSignIn = ({ oauthUrl }) => {
    return (
        <div className="flex justify-center">
            <a
                href={oauthUrl}
                className="inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-bold text-lg rounded hover:bg-blue-600 hover:text-white transition-colors duration-300"
            >
                Sign in with Google
            </a>
        </div>
    );
};

export default GoogleSignIn;