import React, { useState, useEffect } from 'react';
import FileGrid from 'src/partials/FileGrid';
import {toast, ToastContainer} from "react-toastify";
import {useParams} from "react-router-dom";
import * as Constants from "../Constants";
import GoogleSignIn from "../partials/GoogleSignIn";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import NavBar from "../partials/NavBar";
import FileSelectorModal from "../partials/FileSelectorModal";
import Alert from "../partials/Alert"; // Adjust the import path according to your project structure

const ECSMIDDrive = () => {
    const [authorized, setAuthorized] = useState(false);
    const [currentProviderName, setCurrentProviderName] = useState('Google Drive');
    const [currentPath, setCurrentPath] = useState('/');
    const [files, setFiles] = useState([]); // Replace with your API call to fetch files
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);
    const params= useParams();
    const [mainInit, setMainInit] = useState(false);
    const [token, setToken] = useState("");
    const SERVER=Constants.SERVER;
    const [alert, setAlert] = useState({ message: "Starting Aug, 2021 Google API allows impersonation which conflicts with JWUT which doesn't allow impersonation. Make sure your drive files are not used by any service accounts before encrypting them" , status: 'error' });


    useEffect(()=>{
        if(localStorage.getItem("api_token"))
            setToken(localStorage.getItem("api_token"));
        // window.main();
        setMainInit(!mainInit);
        //testRead();
    },[]);
    useEffect(()=>{
        console.log('#####>Params changed: ',params);

        if(localStorage.getItem("api_token"))
            setToken(localStorage.getItem("api_token"));
        else{
            window.location.replace('/login');
        }
        if(params.hasOwnProperty("authorized")){
            console.log('setting authorized');
            setAuthorized(true);
            console.log('getting list of files');
            getListOfFiles();
        }

    },[params]);
    function getListOfFiles(){
        const t=token || localStorage.getItem("api_token");
        fetch(`${SERVER}/api/v1/drive/list-files?id=${t}`)
            .then((result)=>result.json())
            .then((result)=>{
                console.log('fetch files',result);
                if(result.status==='success'){
                    setFiles(result.data);
                    notify("Click on any file to encrypt or decrypt, we don't store cache on our servers as changes are directly made on your Google Drive account!");
                }else{
                    notify('Authorization failed, please try again!');
                }
            })
            .catch((err)=>console.log(err));
    }

    const driveLogin = () => {
        // Your login logic
    };

    const back = () => {
        // Your back logic
    };

    const render = () => {
        // Your render logic
    };

    function changeAccount() {
        toast("Not allowed to switch Google Auth at this time");
    }

    return (
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
        <div className="row">
            <div className="col-10 grid-margin stretch-card" style={{ display: authorized ? "none" : "block" }}>
                <div className="card">
                    <div className="card-body place-content-start">
                        <div className="place-content-start align-content-start justify-content-start">
                            <h4 className="align-self-start align-content-start justify-content-start align-items-start p-4 text-2xl">
                                Sign in with your cloud storage provider
                            </h4>
                            <div className="w-25 p-2 mx-0">
                                {!authorized ? <GoogleSignIn oauthUrl={`${SERVER}/api/v1/auth?id=DRIVE${token}`} />:<div>
                                    <button className="btn btn-danger btn-lg" onClick={(e)=>changeAccount()}>Change Account</button>
                                </div>}
                            </div>
                            <div className="w-25 p-2 mx-0">
                                <button className='btn btn-primary place-content-start p-2.5 w-100 justify-content-start align-content-start' disabled={true}>
                                    <i className="mdi mdi-dropbox text-xl p-2"></i>Sign in with Dropbox
                                </button>
                            </div>
                            <div className="w-25 p-2 mx-0">
                                <button className='btn btn-primary place-content-start p-2.5 w-100' disabled={true}>
                                    <i className="mdi mdi-amazon-drive text-xl"></i>Sign in with AWS S3
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body place-content-start">
                        <div style={{ display: authorized ? "block" : "none" }}>
                            <Alert alert={alert} />
                            <h4 className="card-title">{currentProviderName}</h4>
                            <button onClick={back} className='btn btn-primary place-content-start pr-5 m-2'>Back</button>

                            <FileGrid files={files} onClick={(id)=>{
                                setTimeout(()=>{
                                    console.log("file clicked",id);
                                    //setFiles(files.map(file => file.id === id ? {...file, isEncrypted: true}:file));
                                    toast("File encryption failed");
                                },3000);
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                    href="https://rapidapi.com/lokdon-lokdon-default/api/lokdon-ecsmid-v-1-0-0/" target="_blank">Get started with ECSMID</a></span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default ECSMIDDrive;
