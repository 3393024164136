import logo from './logo.svg';
import './App.css';
import SideBar from './partials/SideBar';
import NavBar from './partials/NavBar';
import Footer from './partials/Footer';
import TopBanner from "./partials/TopBanner";
import React, {useEffect, useState} from "react";
import * as Constants from "./Constants";
import {toast, ToastContainer} from "react-toastify";



function App() {
    const [isSideBarOpen, setIsSideBarOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [cpuUsage, setCpuUsage] = useState(0);
    const [memoryUsage, setMemoryUsage] = useState(0);
    const [diskUsage, setDiskUsage] = useState(0);
    const [APICallCount, setAPICallCount] = useState(0);
    const [APIUsageByCountry, setAPIUsageByCountry] = useState([]);
    const [rowsRemaining, setRowsRemaining] = useState(0);
    const [rowsTotal, setRowsTotal] = useState(500);
    const [form, setForm] = useState({ encrypt: true, text: "",  method: "1", result: "Expect results here" });
    const [processes, setProcesses] = useState([]);
    const [token, setToken] = useState("");
    const SERVER = Constants.SERVER;
    const [formResponse, setFormResponse] = useState("");
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);


    useEffect(() => {
        if(localStorage.getItem("api_token")){
            let t=localStorage.getItem("api_token");
            console.log('TOKEN RET',t);
            setToken(t);

        }else{
            window.location.replace("/login");
        }
         //getPerformanceStats();
         //getProcessInfo();
    },[]);
    useEffect(()=>{
        if(token.length>0){
          getProcessInfo(token);
          checkToken(token);
          getPerformanceStats();
        }
    },[token]);

    function checkToken(token) {
        const endpoint=Constants.SERVER+"/v1/auth/verify";
        fetch(endpoint, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("encrypt-RESPONSE", data);
                if(data.status==="success"){
                    //token valid
                    notify(data.message);
                }else{
                    notify("Your API key has been expired, please login with a valid API key!");
                    setTimeout(()=>{
                        localStorage.removeItem("api_token");
                        window.location.href='/login';
                    },2000);
                }
            })
            .catch(error => {
                console.log("ENCRYPT error", error);
            });
    }

    function getProcessInfo(t=token){
        window.setInterval(function(){
            fetch(SERVER+"/util/processes",
                {
                    method: "GET",
                    headers: {
                        "Authorization":"Bearer "+token,
                    }
                })
                .then(response => response.json())
                .then(data => {
                    console.log("getProcessInfo",data);
                    if(data.status==="success"){
                        let procArray=data.data;
                        while(processes.length>0){
                            processes.pop();
                        }
                        for(let i=0;i<procArray.length;i++){
                            if(procArray[i].command.includes("java -jar target/lokdon-image.jar")){
                                setCpuUsage(procArray[i].cpu);
                                setMemoryUsage(procArray[i].mem);
                            }
                            processes.push({
                                name:procArray[i].command.includes("java -jar target/lokdon-image.jar") ? "ECSMID V2":procArray[i].command,
                                pid:procArray[i].pid,
                                cpu:procArray[i].cpu,
                                memory:procArray[i].mem,
                                user:procArray[i].user,
                            });
                        }
                    }
                })
                .catch(error => {
                    console.log("error",error);
                });
        },2000);

    }
    function getPerformanceStats(){
        window.setInterval(function(){
            setDiskUsage(Math.floor(Math.random() * 100));
            setAPICallCount(Math.floor(Math.random() * 100));
            setAPIUsageByCountry([
                {country:"US",count:Math.floor(Math.random() * 100)},
                {country:"UK",count:Math.floor(Math.random() * 100)},
                {country:"CA",count:Math.floor(Math.random() * 100)},
                {country:"MX",count:Math.floor(Math.random() * 100)},
                {country:"IN",count:Math.floor(Math.random() * 100)}
            ]);
            fetch(SERVER+"/util/cpu",{
                method: "GET",
                headers: {
                    "Authorization": "Bearer FXB/NhscLTEuBk8wIWAkRC8RYRALECwBK1FyBC4cXH4UJGYTFGISASh/cgcBCVYA.FXNfEBEBNSsuQ3AbOm5EeQ8QRxEJEjUxIXlgBy4hAFM3JH48FGEiNiF7XhMuFThVFXIGERdtDTIQWV8xJBUkUxU2QxYQEipvLgZpMSZgJGcVNmURCxgVNSF5XzE7OyReKBB5FhFtCQkTZ1cwJxAneRcKejMUJxQ0LgQIQDUUEwUVNVsRCxR7eQ==.w7vDgcSyxLjEtsSjIcSyNsOmwr/EizDCqjDEm8SCxKU="
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log("cpu",data);
                    setCpuUsage(data.cpu);
                    setMemoryUsage(data.mem);
                }
                )
                .catch(error => {
                    console.log("error",error);
                });
        },2000);
    }

    function shouldDecrypt(value){
        let uniCount=0;
        for(let i=0;i<value.length;i++){
            if(value.charCodeAt(i)>127){
                uniCount++;
            }
        }
        return uniCount>value.length/2;
    }

    function inputChanged(event) {
        //setForm({ ...form, [event.target.name]: event.target.value });
        let value=event.target.value;
        console.log("input changed",value);
        if(shouldDecrypt(value)){
            setForm({ encrypt: false, text: value, method: "1", result: "" });
        }else{
            setForm({ encrypt: true, text: value, method: "1", result: "" });
        }
    }

    function encryptText(event) {
        event.preventDefault();
        console.log("encrypting text",form.text);
        if(form.encrypt) {
            fetch(SERVER + "/v1/encrypt/generic", {
                method: "POST",
                body: JSON.stringify({
                    "text": form.text,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log("encrypt-RESPONSE", data);
                    if(data.status==="success"){
                        console.log("RESULT UPDATED !!!!!!!!!!!!!!!!");
                        setFormResponse(data.cipher_text);
                        window.document.getElementById("result").value=data.cipher_text;
                    }
                })
                .catch(error => {
                    console.log("ENCRYPT error", error);
                });
        }else{
            fetch(SERVER + "/v1/decrypt/generic", {
                method: "POST",
                body: JSON.stringify({
                    "cipher_text": form.text,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log("encrypt-RESPONSE", data);
                    if(data.status==="success"){
                        setFormResponse(data.text);
                        window.document.getElementById("result").value=data.text;
                    }
                })
                .catch(error => {
                    console.log("ENCRYPT error", error);
                });
        }


    }

    return (
    <div className="App">
      <div className="container-scroller">
          <TopBanner/>
          <SideBar/>
            <div className="container-fluid page-body-wrapper">
                <NavBar/>
                <div className="main-panel bg-gradient-to-r">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="d-flex align-items-center align-self-start">
                                                    <h3 className="mb-0">{cpuUsage}%</h3>
                                                    <p className="text-success ms-2 mb-0 font-weight-medium">CPU</p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="icon icon-box-success ">
                                                    <span className="mdi mdi-waves icon-item"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-muted font-weight-normal">CPU Usage</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="d-flex align-items-center align-self-start">
                                                    <h3 className="mb-0">{memoryUsage} MB</h3>
                                                    <p className="text-success ms-2 mb-0 font-weight-medium"></p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="icon icon-box-success">
                                                    <span className="mdi mdi-chip icon-item"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-muted font-weight-normal">Memory Usage</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="d-flex align-items-center align-self-start">
                                                    <h3 className="mb-0">{diskUsage} MB</h3>
                                                    <p className="text-danger ms-2 mb-0 font-weight-medium">includes-cache</p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="icon icon-box-danger">
                                                    <span className="mdi mdi-harddisk icon-item"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-muted font-weight-normal">Disk Usage</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="d-flex align-items-center align-self-start">
                                                    <h3 className="mb-0">{rowsRemaining}/{rowsTotal} rows</h3>
                                                    <p className="text-success ms-2 mb-0 font-weight-medium">remaining/quota</p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="icon icon-box-success ">
                                                    <span className="mdi mdi-currency-usd icon-item"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-muted font-weight-normal">Quota Remaining</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Your ECSMID Quota</h4>
                                        <canvas id="transaction-history" className="transaction-chart"></canvas>
                                        <div
                                            className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                            <div className="text-md-center text-xl-left">
                                                <h6 className="mb-1">Encrypted data this month</h6>
                                                <p className="text-muted mb-0">from {new Date(Date.now()-(60*60*24*30)).toDateString()}</p>
                                            </div>
                                            <div
                                                className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                <h6 className="font-weight-bold mb-0">1500 MB</h6>
                                            </div>
                                        </div>
                                        <div
                                            className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                            <div className="text-md-center text-xl-left">
                                                <h6 className="mb-1">Total encrypted data size:</h6>
                                                <p className="text-muted mb-0"></p>
                                            </div>
                                            <div
                                                className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                <h6 className="font-weight-bold mb-0">2600 MB</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-row justify-content-between">
                                            <h4 className="card-title mb-1">Available Actions</h4>
                                            <p className="text-muted mb-1">Available actions on </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <form onSubmit={encryptText}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group h-full">
                                                                <label htmlFor="first-name">Input:</label>
                                                                <textarea onInput={inputChanged} className="form-control h-full text-white" rows="5"
                                                                       id="input" name="input" placeholder="Something to encrypt/decrypt"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 h-full">
                                                            <div className="form-group">
                                                                <label htmlFor="encryptionMode">Encryption Mode:</label>
                                                                <select className="form-control text-white"
                                                                        id="encryptionMode" name="encryptionMode">
                                                                    <option value="">Select Encryption Mode</option>
                                                                    <option value="1">General Text</option>
                                                                    <option value="2">Passwords</option>
                                                                    <option value="3">Transactional Pin</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="result">Results:</label>
                                                                <textarea className="form-control h-100 text-black" id="result"
                                                                          name="result" disabled={true}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className={form.encrypt ? "btn btn-primary btn-lg my-20":"btn btn-success btn-lg my-20"}>{form.encrypt ? "Encrypt":"Decrypt"}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Current Processes Running</h4>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th> Process Name</th>
                                                    <th> Process ID</th>
                                                    <th> User</th>
                                                    <th> CPU</th>
                                                    <th> MEM</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    processes.map((process, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {process.name}
                                                                </td>
                                                                <td> {process.pid}</td>
                                                                <td> {process.user}</td>
                                                                <td>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-primary"
                                                                             role="progressbar"
                                                                             style={{width: process.cpu+"%"}}
                                                                             aria-valuenow={process.cpu} aria-valuemin="0"
                                                                             aria-valuemax="100"></div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-success"
                                                                             role="progressbar"
                                                                             style={{width: process.mem+"%"}}
                                                                             aria-valuenow={process.mem} aria-valuemin="0"
                                                                             aria-valuemax="100"></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="d-sm-flex justify-content-center justify-content-sm-between">
                            <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                href="https://rapidapi.com/lokdon-lokdon-default/api/lokdon-ecsmid-v-1-0-0/" target="_blank">Get started with ECSMID</a></span>
                        </div>
                    </footer>
                </div>
            </div>
      </div>
        <ToastContainer/>
    </div>
  );
}

export default App;
