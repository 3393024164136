import React, {useState} from "react";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import NavBar from "../partials/NavBar";
import Terminal from "../partials/Terminal";
import Dropzone from "../partials/DropZone";

const EncryptText = () => {
    const[line, setLine] = useState("...");
    const[outputColor, setOutputColor] = useState("text-red-500");
    const [form, setForm] = useState({ encrypt: true, text: "",  method: "1", result: "Expect results here" });

    return(
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">


                                                <Dropzone/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                    href="src/pages/EncryptFile" target="_blank">Get started with ECSMID</a></span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EncryptText;