import React, {useEffect, useState} from "react";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import NavBar from "../partials/NavBar";
import Terminal from "../partials/Terminal";
import FileSystemNavigator from "../partials/FileSystemNavigator";
import TreeItem from "@mui/lab/TreeItem";
import * as Constants from "../Constants";
import DirViewer from "../partials/DirViewer";
import Folder from "../partials/Folder";
import Draggable from "react-draggable";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const ECSMIDFTP = () => {
    const[line, setLine] = useState("...");
    const [token, setToken] = useState("");
    const SERVER=Constants.SERVER;
    const [file, setFile] = useState(null);
    const [address, setAddress]=useState('');
    const [saveTo,setSaveTo]=useState('');
    const [currentPath, setCurrentPath] = useState([]);
    const [files,setFiles]=useState([]);
    const [currentPath2, setCurrentPath2] = useState([]);
    const [files2,setFiles2]=useState([]);
    const [lastPath,setLastPath]=useState('');
    const [lastPath2,setLastPath2]=useState('');
    const [currentItem,setCurrentItem]=useState({});
    const toastId = React.useRef(null);
    const notify = (msg,options={}) => toastId.current = toast(msg,options);
    const dismiss = () => toast.dismiss(toastId.current);

    function dragStarted(e, content) {
        if(content.folder.isFile){
            setCurrentItem(content);
        }else{
            //show toast, folder not supported yet
        }
    }

    function dragDropped(e, content) {
        console.log('transferring file ',currentItem);
        console.log('transferring to dir',content);
        notify('Encrypting and transferring '+currentItem.folder.name+' to '+content.folder.path+' on '+address,{
            autoClose: false,
        });
        const payload={
            selfPath: currentItem.folder.path,
            remotePath: content.folder.path,
            remoteAddress: content.isRemote ? address:SERVER,
            encryptionMethod: 'ECSMID-PERFORMANCE'
        };
        let endpoint=content.isRemote ? SERVER:address;
        fetch(endpoint+'/v1/ecsmid/share',{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json'
            }
        }).then((result)=> result.json())
            .then((result)=>{
                dismiss();
                if(result.status==='success'){
                    notify('File transfer completed successfully');
                }else{
                    notify(result.message);
                }
            })
            .catch((err)=>{
                dismiss();
                console.log('err',err);
            })
    }

    const render= content =>(
            <Folder name={content.folder.name} onClick={(e)=>descend(e,content.folder,content.isRemote)} isFile={content.folder.isFile} onDragStart={(e)=>{dragStarted(e,content)}} onDrop={(e)=>{dragDropped(e,content)}} ></Folder>
        )

    function back(isRemote){
        let dir=isRemote ? lastPath2:lastPath;
        if(dir.length>0) {
            let p = [{
                path: dir+'/..',
            }
            ];
            let addr = isRemote ? address : SERVER;
            listDir(addr, p, (list) => {
                if (isRemote) {
                    setLastPath2(dir+'/..');
                    setCurrentPath2([...list])
                }
                else {
                    setLastPath(dir+'/..');
                    setCurrentPath([...list]);
                }

            });

        }
    }

    function descend(e, folder, isRemote) {
        console.log('#####> dir changed to ',folder,isRemote);
        if(!folder.isFile) {
            let addr = isRemote ? address : SERVER;
            if (isRemote) {
                setLastPath2(folder.path);
            } else {
                setLastPath(folder.path);
            }
            listDir(addr, [folder], (list) => {
                if (isRemote)
                    setCurrentPath2([...list]);
                else setCurrentPath([...list]);
            });
        }else{
            //create draggable content for file
            //start drag on mouse click

        }
    }
    useEffect(()=>{
        if(localStorage.getItem("api_token")){
            let t=localStorage.getItem("api_token");
            console.log('TOKEN RET',t);
            setToken(t);
        }else{
            window.location.replace("/login");
        }
    },[]);
    useEffect(()=>{
        if(token.length>0){
            initializeSelf();
        }
    },[token]);

    function connect(e) {
        e.preventDefault();
        let addr=e.target[0].value;
        setAddress(addr);
        let p=[
            {
                path: '/',
                name: '/',
                isFile: false,
                children:[]
            }
        ];
        //setCurrentPath(p);
        listDir(addr, p, (list)=>{
            console.log('done',list);
            setCurrentPath2([...list]);
        });
        console.log('selected address',addr);
    }
    function initializeSelf(){
        let p=[
            {
                path: '/',
                name: '/',
                isFile: false,
                children:[]
            }
        ];
        //setCurrentPath(p);
         listDir(SERVER, p, (list)=>{
             console.log('done',list);
             let k=currentPath;
             for(let i=0;i<list.length;i++){
                 k[i]=list[i];
             }
             setCurrentPath([...list]);
         });
    }

    function listDir(server,path, done){
        const endpoint=server+"/v1/dir";
        fetch(endpoint,{
            method: "POST",
            body: JSON.stringify({
                path: path[0].path
            }),
            headers: {
                "Authorization":"Bearer "+token,
                "Content-Type": "application/json"
            }
        }).then((result)=>result.json())
            .then((result)=>{
                console.log('result',result);
                if(result.status==='success'){
                    done(result.files);
                }
            })
            .catch((err)=>console.log('error',err));
    }


    return(
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-6 grid-margin stretch-card" style={{zIndex: 999999}}>
                                    <div className="card">
                                        <div className="card-body place-content-start">
                                            <h4 className="card-title">This Server</h4>

                                                    <button onClick={(e)=>back(false)} className='btn btn-primary place-content-start pr-5 m-2'>Back</button>
                                                    <DirViewer path={currentPath} render={render} isRemote={false}/>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Transfer Files</h4>
                                            <p className="card-description"> You can securely transfer files to another server running ECSMID.<br/> The other server must be owned by the same user i.e you, for this to work!</p>
                                            <form className="forms-sample p-2" onSubmit={connect}>

                                                <div className="form-group">
                                                    <label htmlFor="txtInput">Server IP Address</label>
                                                    <input type="url" className="form-control h-100 text-white" id="txtInput"
                                                              required={true} placeholder="IP address of the server"/>
                                                </div>
                                                <button type="submit" className="btn btn-primary me-2">Connect</button>
                                            </form>
                                            <button  onClick={(e)=>back(true)} className='btn btn-primary place-content-start pr-5 m-2'>Back</button>
                                            <DirViewer  path={currentPath2} render={render} isRemote={true}/>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © LokDon, LLC 2024</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a
                                    href="https://rapidapi.com/lokdon-lokdon-default/api/lokdon-ecsmid-v-1-0-0/" target="_blank">Get started with ECSMID</a></span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}
export default ECSMIDFTP;