import React, { useState } from 'react';

const FileSelectorModal = ({ files, onSelectFile, onClose }) => {
    const [selectedFileId, setSelectedFileId] = useState(null);

    const handleFileSelect = (fileId) => {
        setSelectedFileId(fileId);
    };

    const handleNext = () => {
        console.log('handleNext',selectedFileId,files);
        onSelectFile(selectedFileId, files.find(file => file.id===selectedFileId).name);
        onClose(); // Assuming onClose will close the modal
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full backdrop-blur" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Select an Excel File</h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500">
                            Choose an Excel spreadsheet to proceed. Only Google Sheets documents are supported. If you don't see your spreadsheet here, open the spreadsheet in Google Sheets and save it as Google Spreadsheet document first.
                        </p>
                        <ul className="my-3 text-left">
                            {files.map((file) => (
                                <li key={file.id} className="my-2">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            className="form-radio"
                                            name="selectedFile"
                                            value={file.id}
                                            onChange={() => handleFileSelect(file.id)}
                                            checked={selectedFileId === file.id}
                                        />
                                        <span className="ml-2 text-dark dark:text-gray-300">{file.name}</span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="items-center px-4 py-3">
                        <button
                            id="ok-btn"
                            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                            onClick={handleNext}
                            disabled={!selectedFileId}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileSelectorModal;
